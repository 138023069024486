<template>
   <div class="wrap padding-top-layout viewer-mgmt">
    <div class="wrap__block">
      <div class="wrap__block__header wrap__header">
        <div class="wrap__block__header__content">
          <span>{{ $t('viewer_management.title_viewer_management')}}</span>
          <div class="helper-icon">
            <img
              :src="getSettingIcon('helper-icon.svg')"
              @mouseover="changeTooltipImage($event)"
              @mouseleave="changeTooltipImage($event, 'mouseleave')"
              alt=""
            >
            <div v-if="showTooltip" class="helper-icon tooltip"
                v-html="$t('viewer_management.tooltip_facility_management')"></div>
          </div>
        </div>
      </div>
      <div class="wrap__status">
        <div class="wrap__status__item">
          <img src="@/assets/icons/clock.svg" alt="" />
          <div class="wrap__status__item__wrap">
            <p>{{$t('common.last_update')}}</p>
            <p>{{ dateTimeFormat(statusUpdate.time) }}</p>
          </div>
        </div>
        <div class="wrap__status__item">
          <img src="@/assets/icons/userTable.svg" alt="" />
          <div class="wrap__status__item__wrap">
            <p>{{$t('common.updated_by')}}</p>
            <p>{{ statusUpdate.user || "" }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="tabs-header tabs-header_width tab-filter">
      <v-tabs v-model="selectedTab" b color="#404D50" background-color="transparent" :vertical="checkLang">
        <v-tab v-for="item in typeSelectionList" :key="item.tab" :disabled="item.isProductPage && !planType.is_product">
          <img :src="require(`@/assets/icons/type-selection/${item.icon}`)" alt="" class="tabs-header_icon"/>
          <span>{{ $t(item.name) }}</span>
        </v-tab>
      </v-tabs>
    </div>
    <div class="tabs-content">
      <v-tabs-items v-model="selectedTab">
        <v-tab-item>
          <div
              class="category-table main-table custom-table"
              :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded']"
            >
              <data-table
                v-show="isGotLayer"
                :data-source="listData"
                :init-grid="initializeGrid"
                :grid-columns="gridColumns"
                :allowAddNew="false"
                :totalData="totalData - 1"
                :lastedRow="lastedRow"
                :showFocus="false"
                :isHasData="isHasData"
                @onHandleActionTable="checkValidationData"
                class="table-viewer"
              />
            </div>
            <dialog-popup
              :dialog="dialogPopup"
              :message="dialogMessage"
              @submit="() => checkboxHandler(false)"
              @close="closePopup"
            />
            <notification-popup :dialog="dialogNotification" :message="messageSavedChange" @submit="dialogNotification = false" />
        </v-tab-item>
        <v-tab-item>
          <ProductPermission v-on:dataChanged="getLatedUpdate" :items="items"></ProductPermission>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { getListPermissionApi, updatePermissionApi } from "@/api/viewer";
import { CollectionView, PopupPosition, Tooltip } from "@mescius/wijmo";
import DialogPopup from "@/components/dialogs/question-popup";
import NotificationPopup from "@/components/dialogs/notification-popup.vue";
import ProductPermission from '@/components/products/viewer/index.vue';
import DataTable from "@/components/category/data-table";
import { ROUTES } from "@/router/constants";
import { formatDateTime } from "@/utils/datetimeFormat";
import { UndoStack } from "@mescius/wijmo.undo";
import { GridEditAction } from "../managements/wijmo-extends";
import { toolTipCustom } from "@/utils/tooltipCustom";
import { getUserInfo } from "@/api/auth";
import { KEYS_CODE } from "@/constants/keyboard";
import * as wjGrid from "@mescius/wijmo.grid";
import * as wjcCore from "@mescius/wijmo";
import { ROLE } from '@/constants/role';
import { TYPE_SELECTION } from '@/constants/products/define-data';
import { getMinWidthViewerStatus } from '@/utils/multiLanguage';
import { getWidthByTextContent, setMinMaxSizeColumns } from '@/utils/calcTextWidth';
export default {
  data() {
    return {
      items               : [
        {
          text    : this.$t('viewer_management.hyperlink_home'),
          disabled: false,
          href    : ROUTES.HOME_DASHBOARD
        },
        {
          text    : this.$t('viewer_management.hyperlink_setting'),
          disabled: false,
          href    : ROUTES.SETTING
        },
        {
          text    : this.$t('viewer_management.label_viewer_management'),
          disabled: true,
          href    : ROUTES.FACILITY_MANAGEMENT
        }
      ],
      flex                : null,
      data                : [],
      listData            : null,
      gridColumns         : [],
      dialogPopup         : false,
      dialogMessage       : this.$t('viewer_management.description_revoke_browsing_permissions'),
      dialogCancelBtn     : false,
      check_status        : null,
      hdrTips             : new Tooltip({
        position   : PopupPosition.Above,
        showAtMouse: true,
        showDelay  : 600,
        cssClass   : "hdr-tip"
      }),
      countErr            : 0,
      testErr             : [],
      statusUpdate        : {
        time: null,
        user: null
      },
      totalData           : null,
      undoStack           : null,
      canUndo             : false,
      canRedo             : false,
      actionCount         : 0,
      stackUndoWhenUncheck: null,
      eventChangeStatus   : null,
      lastedRow           : {},
      contractorName      : "",
      showTooltip         : false,
      layers              : [],
      isGotLayer          : false,
      listOriginalData    : [],
      dialogNotification: false,
      isEditDataTable: 0,
      selectedTab: 0,
      typeSelectionList: TYPE_SELECTION,
      isSmallScreen: window.innerWidth <= 480
    };
  },

  components: {
    DataTable,
    DialogPopup,
    NotificationPopup,
    ProductPermission
  },

  computed: {
    ...mapState("userData", ["contractor", "currentUser", "planType"]),
    ...mapState("commonApp", ["loadMore", "isExpand"]),
    ...mapState("actionsTable", ["startAction"]),
    ...mapState("registerData", ["isFullScreen"]),

    isHasData() {
      return this.canUndo || this.canRedo;
    },
    messageSavedChange() {
      return this.$t('viewer_management.message_saved_changes')
    },
    checkLang() {
      return ['en', 'es', 'ar-AE'].includes(this.$i18n.locale) && this.isSmallScreen;
    }
  },

  async mounted() {
    await this.updateBreadCrumb(this.items);
    this.gridColumns = this.getGridColumns();
    this.actionUpdateIsLoading(true)
    await this.checkUserInfo();
    this.isGotLayer = false;
    const permissionApiResponse = await getListPermissionApi({ order: "user_id,branch_id" });
    this.actionUpdateIsLoading(false)

    this.layers                 = permissionApiResponse?.layer.map((layer) => {
      let binding = `layer_${layer.layer_index}`;
      if (layer.layer_index === 1) {
        binding = "business_name";
      } else if (layer.layer_index === 2) {
        binding = "country";
      }
      return {
        ...layer,
        binding
      };
    }).sort((a, b) => a.layer_index - b.layer_index);
    this.isGotLayer             = true;
    this.statusUpdate           = {
      time: permissionApiResponse.updated_at_latest,
      user: permissionApiResponse.user_updated
    };
    const mappedDataPermission  = this.mapDataPermission(permissionApiResponse);
    mappedDataPermission.map((item) => {
      this.data.push({
        ...item,
        branch_name: this.contractorName
      });
    });
    this.totalData = permissionApiResponse.data.length;

    this.defineTable();
    this.createUndoStack();
    window.addEventListener('resize', this.onResize);
  },

  methods: {
    ...mapActions("commonApp", ["updateBreadCrumb", "actionUpdateIsLoading"]),
    ...mapActions("actionsTable", ["actionUpdateStatusBtn"]),
    getLatedUpdate(newData) {
      this.statusUpdateProduct = newData;
      this.statusUpdate = newData;
    },

    getWidthByText(string) {
      let text = document.createElement("span");
      document.body.appendChild(text);
      text.style.width      = "auto";
      text.style.position   = "absolute";
      text.style.whiteSpace = "no-wrap";
      text.style.font       = "Source Han Sans";
      text.style.fontSize   = 13 + "px";
      text.innerHTML        = string + "標準値";
      const width           = text.clientWidth;
      document.body.removeChild(text);
      return (width + 12) < 90 ? 90 : width + 12;
    },

    defineTable() {
      this.gridColumns       = this.getGridColumns();
      this.listData          = new CollectionView(this.data, {
        trackChanges: true,
      });
      this.listOriginalData  = JSON.parse(JSON.stringify(this.listData.items));
      this.flex.columnGroups = this.getGridColumns();
      this.listData.currentItem = null;
      setMinMaxSizeColumns(this.flex, this.data);
    },

    initializeGrid(flexgrid) {
      this.flex = flexgrid;

      document.addEventListener("keydown", (e) => {
        if (
          (e.metaKey || e.ctrlKey) &&
          [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW].includes(e.keyCode)
        ) {
          e.preventDefault();
        }
      });

      flexgrid.hostElement.addEventListener(
        "keydown",
        (e) => {
          // console.log('keydown: ', e);
          if (e.metaKey || e.ctrlKey) {
            if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange        = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col);
              flexgrid.selection     = cellRange;

              // re-select after add more
              setTimeout(() => {
                flexgrid.selection = cellRange;
              }, 200);
            } else if (e.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection     = new wjGrid.CellRange(0, currentSelection.col);
            } else if (e.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection     = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1);
            } else if (e.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection     = new wjGrid.CellRange(currentSelection.row, 1);
            }
          }
        },
        false
      );
      flexgrid.pasted.addHandler((s, e) => {
        if (e.range.row === e.range.row2) {
          // paste one row
          s.onItemsSourceChanged();
        }
      });

      flexgrid?.beginningEdit.addHandler((s, e) => {
        let column        = s.columns[e.col];
        let rowValue      = s.rows[e.row]._data;
        this.check_status = rowValue;

        if (column.binding !== "status") {
          return;
        }

        this.stackUndoWhenUncheck = new GridEditAction(s, e);
        if(rowValue.role_id !== ROLE.ADMIN) {
          this.handleCheckBox(rowValue);
        }

        e.cancel = true;
      });

      flexgrid?.formatItem.addHandler((s, e) => {
        const colBinding        = e.panel.columns[e.col].binding;
        const colBindingTooltip = ["status"];
        if (!colBindingTooltip.includes(colBinding)) {
          return;
        }
        if (e.panel == s.columnHeaders) {
          if (colBinding === "status") {
            toolTipCustom(e, "t1", this.$t('viewer_management.tooltip_status'), this.hdrTips);
          }
        }
        if (e.panel == s.cells && colBinding === 'status') {
          const roleId = s.rows[e.row]._data?.role_id;
          if(roleId === ROLE.ADMIN) {
            wjcCore.addClass(e.cell, "is-admin-read-only"); 
          }
        }
      });
    },
    getGridColumns() {
      const mapLayers = this.layers?.map((layer) => ({
        header      : layer.layer_name,
        binding     : layer.binding,
        minWidth    : getWidthByTextContent(layer.layer_name),
        maxWidth    : 980,
        allowSorting: false,
        isReadOnly  : true,
        cssClassAll : "no-tooltip single-row",
        wordWrap    : true
      }));
      return [
        {
          header      : "#",
          binding     : "id",
          allowSorting: false,
          isReadOnly  : true,
          visible     : false
        },
        {
          header      : "#",
          binding     : "role_id",
          allowSorting: false,
          isReadOnly  : true,
          visible     : false
        },
        {
          header      : this.$t('viewer_management.table_user_email'),
          binding     : "user_email",
          minWidth    : getWidthByTextContent(this.$t('viewer_management.table_user_email')),
          maxWidth    : 980,
          allowSorting: false,
          isRequired  : false,
          allowMerging: true,
          isReadOnly  : true,
          cssClassAll : "no-tooltip single-row",
          wordWrap    : true
        },
        {
          header      : "契約者名",
          binding     : "user_id",
          minWidth    : 220,
          maxWidth    : 980,
          allowSorting: false,
          isRequired  : false,
          visible     : false,
          wordWrap    : true
        },
        {
          header      : this.$t('viewer_management.table_contractor_name'),
          binding     : "branch_name",
          minWidth    : getWidthByTextContent(this.$t('viewer_management.table_contractor_name')),
          maxWidth    : 980,
          allowSorting: false,
          isReadOnly  : true,
          cssClassAll : "no-tooltip single-row",
          wordWrap    : true
        },
        {
          header      : this.$t('viewer_management.table_company_name'),
          binding     : "company_name",
          minWidth    : getWidthByTextContent(this.$t('viewer_management.table_company_name')),
          maxWidth    : 980,
          allowSorting: false,
          isReadOnly  : true,
          cssClassAll : "no-tooltip single-row",
          wordWrap    : true
        },
        ...mapLayers,
        {
          header      : this.$t('viewer_management.table_status'),
          binding     : "status",
          minWidth    : getMinWidthViewerStatus(),
          width: '*',
          allowSorting: false,
          wordWrap    : true,
          cssClassAll : "single-row"
        }
      ];
    },

    handleCheckBox(value) {
      if (value.status) {
        this.dialogPopup     = true;
        this.dialogCancelBtn = true;
      } else {
        this.checkboxHandler(true);
      }
    },

    checkboxHandler(check) {
      this.listData.beginUpdate();
      this.listData.currentItem.status = check;
      this.listData.endUpdate();
      if (this.stackUndoWhenUncheck) {
        this.undoStack.pushAction(this.stackUndoWhenUncheck);
        this.stackUndoWhenUncheck = null;
      }
      this.closePopup();
    },

    closePopup() {
      this.dialogPopup     = false;
      this.dialogCancelBtn = true;
    },

    dateTimeFormat(dateTime) {
      return formatDateTime(dateTime);
    },

    async checkUserInfo() {
      await getUserInfo()
        .then((res) => {
          this.contractorName = res.contractor.name;
        })
        .catch(() => {
        });
    },

    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return "";
    },

    changeTooltipImage(event, type) {
      if (type === "mouseleave") {
        event.target.src                        = this.getSettingIcon("helper-icon.svg");
        event.target.parentElement.style.cursor = "default";
        this.showTooltip                        = false;
      } else {
        event.target.src                        = this.getSettingIcon("helper-icon_active.svg");
        event.target.parentElement.style.cursor = "pointer";
        this.showTooltip                        = true;
      }
    },

    createUndoStack() {
      this.undoStack = new UndoStack("#undoable-table", {
        maxActions  : 50,
        stateChanged: (s) => {
          this.canUndo = s.canUndo;
          this.canRedo = s.canRedo;
        }
      });
    },
    checkValidationData() {
      this.submitData();
    },
    submitData() {
      let edited = {};
      let self   = this;
      self.listOriginalData.forEach(function(item, itemIndex) {
        let newItemIndex = self.listData.items.findIndex(currentItem => currentItem.id === item.id);
        let newItem      = self.listData.items[newItemIndex];
        if (newItem.status !== item.status) {
          edited[itemIndex] = {
            id    : item.id,
            status: newItem.status ? 1 : 0
          };
        }
      });
      // call api
      if (Object.values(edited).length) {
        updatePermissionApi({ data: Object.values(edited) }).then((response) => {
          this.statusUpdate     = {
            time: response.latest_record.updated_at_latest,
            user: response.latest_record.user_updated
          };
          this.listOriginalData = JSON.parse(JSON.stringify(this.listData.items));
          this.dialogNotification = true;
          this.undoStack.clear();
        }).catch((error) => {
          console.log(error);
        });
      }
    },
    onResize() {
      this.isSmallScreen = window.innerWidth <= 480;
    },

    mapDataPermission(dataPermissions) {
      const users = dataPermissions.users
      const branches = dataPermissions.branches;
      const mappedData = dataPermissions.data.map((item) => {
          const { id, user_id, branch_id, status } = item
          const user_email = users[user_id].email;
          const role_id = users[user_id].role_id;
          const branch = branches[branch_id]
          return {
          id,
          user_email,
          role_id,
          ...branch,
          status
          };
      })
      return mappedData;
    },
  },
  watch  : {
    startAction(newValue, _) {
      if (newValue.undo) {
        this.undoStack.undo();
      } else if (newValue.redo) {
        this.undoStack.redo();
      }
    },

    canUndo(newValue) {
      this.actionUpdateStatusBtn({
        undo: newValue,
        redo: this.canRedo
      });
    },

    canRedo(newValue) {
      this.actionUpdateStatusBtn({
        undo: this.canUndo,
        redo: newValue
      });
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  }
};
</script>

<style scoped lang="scss">
@import './styles/index.scss';

.v-window {
  overflow: visible;
}
.wrap__block {
    padding-bottom: 0px;
  }
  .tabs-header {
    margin: 48px 20px 48px 0;
    .tabs-header_icon {
      margin-right: 8px;
    }
  }
  .theme--light {
    &.v-tabs {
      .v-tab--active::before {
        opacity: 0;
      }
    }
  }
 .tabs-content {
  margin: 0px -20px;
 }
  @include desktop {
    .tabs-header {
      margin: 48px 40px 48px;
    }
    .tabs-content {
      margin: 0px;
    }
  }
</style>

<style lang="scss">
@media (max-width: 1024px) {
  .tabs-header.tab-filter {
      margin: 48px 0;
  }
  .en {
    .tab-filter {
      .v-tabs-bar {
        height: 76px !important;
      }
    }
  }
  .v-slide-group__content .v-tab {
    height: 38px !important;
  }
 
}
.tab-filter {
  .v-tabs-bar {
    height: 38px;
  }
}
</style>